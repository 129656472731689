import * as Types from './declarations'

export const THUMB_DIR = 'public/thumbs'

export const THUMB_SIZES: Types.ThumbSize[] = [
	{ name: 'large', width: 1400 },
	{ name: 'medium', height: 250 },
	{ name: 'small', height: 120 },
]

export const MY_FAKE_GUIDE_ACCOUNT_ID = 9

export const continentBlurbs: Record<string, string> = {
	africa: `<p>Africa presents a unique blend of desert and highland terrain, making it a fascinating destination for mountaineers. This continent is home to iconic <strong>6000m peaks</strong> like <strong>Mount Kilimanjaro</strong> in Tanzania, one of the Seven Summits and the highest point in Africa. Kilimanjaro’s diverse landscapes, from tropical forests to alpine deserts, offer a one-of-a-kind climbing experience that is accessible even for <em>intermediate-level mountaineers</em>.</p>
	<p>Other notable African climbs include Mount Kenya, with its rugged, glaciated peaks and technical routes that challenge even seasoned climbers. The Rwenzori Mountains, located between Uganda and the Democratic Republic of the Congo, provide a remote and scenic experience with lush vegetation and a surprising amount of snow and glaciers. These routes not only test your endurance but also immerse you in Africa’s breathtaking biodiversity.</p>
	<p>Africa offers an ideal environment for those looking to transition to higher-altitude climbs. With its mixture of technical challenges and non-technical treks, the continent provides valuable experience for mountaineers aiming to progress to tougher peaks around the world.</p>`,

	asia: `<p>Asia stands as the ultimate destination for high-altitude <strong>mountaineering expeditions</strong>, boasting some of the world’s most celebrated and challenging peaks. The <strong>Himalayas</strong> and <strong>Karakoram</strong> ranges feature iconic 6000m climbs, with technical routes that test climbers’ skills and resilience. Peaks like <strong>Mount Everest</strong> and <strong>K2</strong> offer steep ascents, unpredictable weather, and diverse terrain, making Asia a focal point for advanced mountaineers.</p>
	<p>Beyond these famous giants, Asia hosts a wide variety of less-crowded mountains, such as <strong>Island Peak</strong> and <strong>Stok Kangri</strong>, which provide thrilling yet accessible experiences for climbers of different skill levels. In Central Asia, the Pamir and Tien Shan ranges present climbers with a rugged and remote landscape where <em>expedition-style ascents</em> are the norm.</p>
	<p>Asia’s mountaineering opportunities are enriched by its cultural landscapes, with regions such as Nepal and Pakistan providing a rich tapestry of heritage and hospitality. Whether you’re looking for technically demanding routes or an immersive journey through isolated mountain villages, Asia delivers an unparalleled mountaineering experience.</p>`,

	europe: `<p>Europe’s diverse alpine settings, from the <strong>Alps</strong> to the <strong>Caucasus</strong>, offer challenging expeditions for climbers of all levels. The continent’s renowned peaks, such as <strong>Mont Blanc</strong> and <strong>Mount Elbrus</strong>, provide climbers with thrilling, high-altitude challenges set against the backdrop of breathtaking scenery. Europe’s well-maintained mountain huts and cable car systems make it uniquely accessible for expeditions.</p>
	<p>For those looking to refine their technical skills, the steep, ice-filled routes of the Caucasus offer a prime training ground. With challenging ridges, glaciated terrain, and a mix of snow and rock, climbers here can improve their alpine techniques in a controlled yet adventurous environment. The Dolomites also present a range of routes that cater to both traditional climbers and modern alpinists.</p>
	<p>Europe's rich history in mountaineering and established infrastructure make it a top destination for those looking to pursue serious <em>alpine climbing</em>. Whether you seek intense, technical climbs or classic summits, Europe’s mountains offer a rewarding combination of adventure and accessibility.</p>`,

	'north-america': `<p>North America offers some of the most rugged and isolated <strong>mountaineering experiences</strong> worldwide. From the towering <strong>Alaskan Range</strong> to the Rocky Mountains, climbers can tackle <em>extreme weather</em>, vast glaciers, and challenging alpine terrain. Peaks like <strong>Denali</strong>, the highest point in North America, are known for their formidable conditions and breathtaking beauty, drawing experienced climbers from across the globe.</p>
	<p>In addition to Alaska, the Pacific Northwest is a hub for mountaineering, featuring peaks such as <strong>Mount Rainier</strong> and <strong>Mount Shuksan</strong>, where climbers navigate icefields, glaciers, and technical rock sections. North America’s national parks, including Yosemite and Grand Teton, also provide unique landscapes that are ideal for rock climbers and alpine mountaineers alike.</p>
	<p>With vast wilderness areas and well-established climbing routes, North America’s mountains offer a true backcountry experience. These peaks require a high level of skill, fitness, and preparation, making them ideal for <em>mountaineers seeking isolation</em> and a deep connection to nature.</p>`,

	'south-america': `<p>The Andes, South America’s longest mountain range, is home to some of the world’s most iconic <strong>high-altitude peaks</strong>, including <strong>Aconcagua</strong>, the highest mountain in the Southern Hemisphere. South America offers a diverse range of climbing experiences, from volcanic summits in Ecuador to jagged peaks in Patagonia, catering to a wide variety of skill levels and expedition styles.</p>
	<p>With their dry climate and vast altiplano, the Andes provide climbers with excellent conditions for high-altitude training. This region is ideal for mountaineers preparing for more extreme altitudes, as the peaks offer serious altitude challenges without the intense technical difficulty found in the Himalayas. Climbers can experience everything from <em>glacier travel</em> to long, sustained ascents.</p>
	<p>South America’s unique blend of natural beauty and cultural richness makes it an unforgettable destination for mountaineers. With breathtaking landscapes, welcoming locals, and a variety of routes, the Andes offer a compelling alternative to other major mountain ranges.</p>`,

	antarctica: `<p><strong>Antarctica</strong> represents the pinnacle of <em>extreme mountaineering</em>, offering one of the most isolated and challenging environments on the planet. With peaks like <strong>Mount Vinson</strong> standing as a testament to nature’s raw power, climbers face freezing temperatures, unpredictable weather, and complete isolation. This continent attracts mountaineers who are prepared for one of the most demanding expedition experiences available.</p>
	<p>Climbing in Antarctica requires extensive preparation and a willingness to embrace harsh conditions. Expeditions here involve long, arduous days of traversing icefields, braving sub-zero temperatures, and managing logistical challenges far beyond those faced on other continents. For climbers seeking a remote and pristine environment, Antarctica offers a unique sense of achievement and a deep connection to nature.</p>
	<p>This icy wilderness is not for the faint-hearted, but for those ready to tackle its immense challenges, Antarctica promises an unparalleled and life-changing mountaineering experience. This is truly the last frontier for <strong>adventurous mountaineers</strong> looking to push their limits.</p>`,
}

export const regions = [
	[
		{
			continent: 'Europe',
			blurb: continentBlurbs['europe'],
			climbBlurb: `<p><strong>Europe</strong> is renowned for its legendary <strong>alpine climbing destinations</strong> like the <strong>Alps</strong> and <strong>Dolomites</strong>. With <em>well-established routes</em> and hut systems, Europe provides climbers with unparalleled <strong>mountain access</strong> and comfort on extended <strong>mountaineering expeditions</strong>. From beginner-friendly routes to intense <strong>technical ascents</strong>, Europe's variety of terrain and historic <em>climbing culture</em> make it a top choice for <strong>mountaineers of all levels</strong>. Key locations such as <strong>Mont Blanc</strong> and the <strong>Eiger</strong> offer climbers breathtaking views, rich tradition, and numerous <strong>high-altitude challenges</strong> that are accessible and rewarding.</p>`,
			countries: [
				{
					label: 'Switzerland',
					href: '/climbing/europe/switzerland',
					isoCode: 'CH',
				},
				{ label: 'France', href: '/climbing/europe/france', isoCode: 'FR' },
				{ label: 'Russia', href: '/climbing/europe/russia', isoCode: 'RU' },
			],
		},
		{
			continent: 'North America',
			blurb: continentBlurbs['north-america'],
			climbBlurb: `<p><strong>North America</strong> offers a diverse range of <strong>mountaineering experiences</strong>, from the towering <strong>Rocky Mountains</strong> to the iconic faces of <strong>Yosemite's</strong> granite cliffs. With notable peaks like <strong>Denali</strong> and <strong>Mount Rainier</strong>, climbers encounter <em>extreme weather</em>, expansive <strong>glacier travel</strong>, and varied landscapes that challenge every skill level. The continent's vast wilderness areas allow for both guided and <em>solo expeditions</em>, making it ideal for those seeking true adventure. From snow-capped summits to technical rock and ice routes, North America's rugged mountains offer opportunities for <strong>alpine climbers</strong> and <strong>backcountry adventurers</strong> alike.</p>`,
			countries: [
				{
					label: 'United States',
					href: '/climbing/north-america/united-states',
					isoCode: 'US',
				},
			],
		},
		{
			continent: 'Africa',
			blurb: continentBlurbs['africa'],
			climbBlurb: `<p><strong>Africa</strong> captivates climbers with its distinct and varied <strong>high-altitude landscapes</strong>. Iconic peaks like <strong>Mount Kilimanjaro</strong> and the challenging <strong>Rwenzori Mountains</strong> provide a unique experience of trekking and climbing through <em>diverse ecosystems</em>, from savannas to alpine deserts. Mountaineers drawn to the natural beauty and <strong>cultural richness</strong> of Africa will find both accessible treks and demanding routes on <strong>6000m peaks</strong>. Africa's summits offer climbers not only physical challenges but also breathtaking scenery and a true sense of accomplishment.</p>`,
			countries: [
				{ label: 'Kenya', href: '/climbing/africa/kenya', isoCode: 'KE' },
				{ label: 'Morocco', href: '/climbing/africa/morocco', isoCode: 'MA' },
				{ label: 'Tanzania', href: '/climbing/africa/tanzania', isoCode: 'TZ' },
			],
		},
		{
			continent: 'Antarctica',
			blurb: continentBlurbs['antarctica'],
			climbBlurb: `<p><strong>Antarctica</strong> represents the ultimate challenge for <em>seasoned mountaineers</em> and <em>adventurers</em> willing to push their limits in some of the world’s most extreme environments. Its remote, icy terrain—home to <strong>Mount Vinson</strong> and other <strong>Antarctic peaks</strong>—offers <strong>high-altitude climbs</strong> and stunningly pristine landscapes. The <em>harsh conditions</em> of Antarctica, from freezing temperatures to powerful winds, demand thorough preparation and <strong>technical skill</strong>. For mountaineers seeking isolation, challenge, and beauty, Antarctica provides an experience unlike any other continent on Earth.</p>`,
			countries: [
				{
					label: 'Antarctica',
					href: '/climbing/antarctica/antarctica',
					isoCode: 'AQ',
				},
			],
		},
	],
	[
		{
			continent: 'Asia',
			blurb: continentBlurbs['asia'],
			climbBlurb: `<p><strong>Asia</strong> is the pinnacle of high-altitude <strong>mountaineering</strong>, offering the <strong>world's tallest peaks</strong> such as <strong>Mount Everest</strong>, <strong>K2</strong>, and <strong>Annapurna</strong>. The <strong>Himalayas</strong> and <strong>Karakoram</strong> ranges are home to some of the most challenging and <em>technically demanding routes</em> in mountaineering, drawing climbers from around the world. Asia’s extensive <em>cultural diversity</em> and stunning landscapes enhance every expedition, from guided climbs to solo challenges. Mountaineers in Asia face everything from steep ice walls to unpredictable weather, making it a top destination for those aiming to test their endurance, skill, and ambition.</p>`,
			countries: [
				{ label: 'China', href: '/climbing/asia/china', isoCode: 'CN' },
				{ label: 'Georgia', href: '/climbing/asia/georgia', isoCode: 'GE' },
				{ label: 'Indonesia', href: '/climbing/asia/indonesia', isoCode: 'ID' },
				{ label: 'India', href: '/climbing/asia/india', isoCode: 'IN' },
				{
					label: 'Kyrgyzstan',
					href: '/climbing/asia/kyrgyzstan',
					isoCode: 'KG',
				},
				{
					label: 'Kazakhstan',
					href: '/climbing/asia/kazakhstan',
					isoCode: 'KZ',
				},
				{ label: 'Nepal', href: '/climbing/asia/nepal', isoCode: 'NP' },
				{ label: 'Pakistan', href: '/climbing/asia/pakistan', isoCode: 'PK' },
				{
					label: 'Tajikistan',
					href: '/climbing/asia/tajikistan',
					isoCode: 'TJ',
				},
				{ label: 'Tibet', href: '/climbing/asia/tibet', isoCode: 'XZ' },
			],
		},
	],
	[
		{
			continent: 'South America',
			blurb: continentBlurbs['south-america'],
			climbBlurb: `<p><strong>South America</strong> is a premier destination for mountaineering, with the <strong>Andes</strong> providing a vast range of <strong>high-altitude challenges</strong>. Notable peaks like <strong>Aconcagua</strong> and <strong>Huascarán</strong> offer thrilling climbs that prepare mountaineers for even higher altitudes. The region's unique <em>volcanic landscapes</em> and remote wilderness attract climbers seeking adventure and natural beauty. Whether you're drawn to the jagged peaks of <strong>Patagonia</strong> or the towering summits of the Ecuadorian Andes, South America combines stunning views with a variety of <em>climbing experiences</em> suitable for all levels.</p>`,
			countries: [
				{
					label: 'Argentina',
					href: '/climbing/south-america/argentina',
					isoCode: 'AR',
				},
				{
					label: 'Bolivia',
					href: '/climbing/south-america/bolivia',
					isoCode: 'BO',
				},
				{
					label: 'Chile',
					href: '/climbing/south-america/chile',
					isoCode: 'CL',
				},
				{
					label: 'Ecuador',
					href: '/climbing/south-america/ecuador',
					isoCode: 'EC',
				},
				{ label: 'Peru', href: '/climbing/south-america/peru', isoCode: 'PE' },
			],
		},
	],
]

export const languagesList = [
	{ value: 'english', label: 'English', group: 'Popular' },
	{ value: 'french', label: 'French', group: 'Popular' },
	{ value: 'german', label: 'German', group: 'Popular' },
	{ value: 'italian', label: 'Italian', group: 'Popular' },
	{ value: 'spanish', label: 'Spanish', group: 'Popular' },
	{ value: 'arabic', label: 'Arabic', group: 'All' },
	{ value: 'chinese', label: 'Chinese', group: 'All' },
	{ value: 'dutch', label: 'Dutch', group: 'All' },
	{ value: 'hindi', label: 'Hindi', group: 'All' },
	{ value: 'japanese', label: 'Japanese', group: 'All' },
	{ value: 'korean', label: 'Korean', group: 'All' },
	{ value: 'polish', label: 'Polish', group: 'All' },
	{ value: 'portuguese', label: 'Portuguese', group: 'All' },
	{ value: 'russian', label: 'Russian', group: 'All' },
	{ value: 'swedish', label: 'Swedish', group: 'All' },
	{ value: 'turkish', label: 'Turkish', group: 'All' },
] as const
